<script>
import { layoutComputed } from "@/state/helpers";

export default {
  components: {},
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
    };
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
    },
  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");

      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                siblingCollapse.classList.remove("show");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2)
                item.firstElementChild.setAttribute("aria-expanded", "false");
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                item1.classList.remove("show");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.hide();
          });
        });
      });
    }
  },

  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition =
          document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="' + ele + '"]');
          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
                const grandparent = parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .previousElementSibling.parentElement.closest(".collapse");
                if (grandparent && grandparent.previousElementSibling) {
                  grandparent.previousElementSibling.classList.add("active");
                  grandparent.classList.add("show");
                }
              }
            }
          }
        }
      }, 0);
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <div id="two-column-menu"></div>

    <ul class="navbar-nav h-100" id="navbar-nav">
      <!-- -------------------- -->
      <!-- gestion des produits -->
      <!-- -------------------- -->
      <li class="menu-title">
        <span data-key="t-menu"> {{ $t("Gestion des Produits") }}</span>
      </li>

      <li class="nav-item">
        <router-link
          to="/add-product"
          class="nav-link"
          data-key="t-create-product"
        >
          <i class="ri-add-line align-bottom me-1"></i>
          <span data-key="t-dashboards">{{ $t("Nouveau produit") }}</span>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link to="/products" class="nav-link" data-key="t-products">
          <i class="bx bx-store-alt"></i>
          <span data-key="t-dashboards"> {{ $t("Liste des produits") }}</span>
        </router-link>
      </li>
      <!-- <li class="nav-item">
          <router-link to="/ecommerce/product-details" class="nav-link" data-key="t-product-Details">
            <i class="bx bx-shopping-bag"></i>
            <span data-key="t-dashboards"> {{ $t("Produits-details") }}</span>
          </router-link>
        </li> -->
      <li class="nav-item">
        <router-link
          to="/add-product-category"
          class="nav-link"
          data-key="t-create-product"
        >
          <i class="ri-database-line"></i>
          <span data-key="t-dashboards">{{
            $t("Gestion des catégories")
          }}</span>
        </router-link>
      </li>
      <!-- <li class="nav-item">
          <router-link to="/comments-on-product" class="nav-link" data-key="t-create-product">
            <i class="ri-discuss-line "></i>
            <span data-key="t-dashboards">{{ $t("Commentaires sur les produits") }}</span>
          </router-link>
        </li> -->

      <!-- -------------------- -->
      <!-- Gestion des formations -->
      <!-- -------------------- -->
      <li class="menu-title">
        <span data-key="t-menu"> {{ $t("Gestion des formations") }}</span>
      </li>

      <li class="nav-item">
        <router-link
          to="/add-course"
          class="nav-link"
          data-key="t-create-product"
        >
          <i class="ri-add-line align-bottom me-1"></i>
          <span data-key="t-dashboards">{{ $t("Ajouter une formation") }}</span>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link to="/courses" class="nav-link" data-key="t-products">
          <i class="bx bx-store-alt"></i>
          <span data-key="t-dashboards"> {{ $t("Liste des formations") }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="/add-course-category"
          class="nav-link"
          data-key="t-create-product"
        >
          <i class="ri-database-line"></i>
          <span data-key="t-dashboards">{{
            $t("Gestion des catégories")
          }}</span>
        </router-link>
      </li>

      <!-- ---------------------- -->
      <!-- Enregistrement de colis -->
      <!-- ---------------------- -->
      <li class="menu-title">
        <span data-key="t-menu"> {{ $t("Calendrier") }}</span>
      </li>
      <li class="nav-item">
        <router-link to="/pickup-dates" class="nav-link" data-key="t-products">
          <i class="bx bx-store-alt"></i>
          <span data-key="t-dashboards"> {{ $t("Date de ramassages") }}</span>
        </router-link>
      </li>


      <!-- -------------------- -->
      <!-- Suivi de colis -->
      <!-- -------------------- -->
      <li class="menu-title">
        <span data-key="t-menu"> {{ $t("Suivi de colis") }}</span>
      </li>

      <li class="nav-item">
        <router-link
          to="/add-request"
          class="nav-link"
          data-key="t-create-product"
        >
          <i class="ri-add-line align-bottom me-1"></i>
          <span data-key="t-dashboards">{{ $t("Ajouter un colis") }}</span>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link to="/request-trackings" class="nav-link" data-key="t-products">
          <i class="bx bx-store-alt"></i>
          <span data-key="t-dashboards"> {{ $t("Liste des colis") }}</span>
        </router-link>
      </li>
      <!-- <li class="nav-item">
        <router-link
          to="/pickup-plane-fees"
          class="nav-link"
          data-key="t-products"
        >
          <i class="bx bx-store-alt"></i>
          <span data-key="t-dashboards"> {{ $t("Colis aérien") }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="/pickup-sea-fees"
          class="nav-link"
          data-key="t-products"
        >
          <i class="bx bx-store-alt"></i>
          <span data-key="t-dashboards"> {{ $t("Colis maritime") }}</span>
        </router-link>
      </li> -->

      <!-- -------------------- -->
      <!--    Requêtes SMS     -->
      <!-- -------------------- -->
      <li class="menu-title">
        <span data-key="t-menu"> {{ $t("Souscriptions") }}</span>
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'immigration-requests.list' }"
          class="nav-link"
          data-key="t-customers"
        >
          <i class="ri-user-heart-line"></i>
          <span data-key="t-dashboards">
            {{ $t("Requêtes d'immigration") }}</span
          >
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'buy-requests.list' }"
          class="nav-link"
          data-key="t-customers"
        >
          <i class="ri-user-heart-line"></i>
          <span data-key="t-dashboards"> {{ $t("Requêtes d'achat") }}</span>
        </router-link>
      </li>
      <!-- <li class="nav-item">
        <router-link
          :to="{ name: 'campagnes-sms-create' }"
          class="nav-link"
          data-key="t-customers"
        >
          <i class="ri-user-voice-line"></i>
          <span data-key="t-dashboards">{{ $t("Lancer une campagne") }}</span>
        </router-link>
      </li> -->

      <!-- ---------------------- -->
      <!-- Gestions des commandes -->
      <!-- ---------------------- -->
      <li class="menu-title">
        <span data-key="t-menu"> {{ $t("Gestion des Commandes") }}</span>
      </li>
      <li class="nav-item">
        <router-link to="/orders" class="nav-link" data-key="t-products">
          <i class="ri-shopping-cart-line"></i>
          <span data-key="t-dashboards"> {{ $t("Les Commandes") }}</span>
        </router-link>
      </li>
      <!-- <li class="nav-item">
        <router-link
          to="/my-dates-settings"
          class="nav-link"
          data-key="t-customers"
        >
          <i class="ri-settings-line"></i>
          <span data-key="t-dashboards"> {{ $t("Dates de départ") }}</span>
        </router-link>
      </li> -->
      <!-- <li class="nav-item">
          <router-link to="/ecommerce/order-details" class="nav-link" data-key="t-product-Details">
            {{ $t("t-product-Details") }}
          </router-link>
        </li> -->

      <!-- -------------------- -->
      <!-- gestions des Clients -->
      <!-- -------------------- -->
      <li class="menu-title">
        <span data-key="t-menu"> {{ $t("Gestion des Clients") }}</span>
      </li>
      <li class="nav-item">
        <router-link to="/customers" class="nav-link" data-key="t-customers">
          <i class="ri-user-heart-line"></i>
          <span data-key="t-dashboards"> {{ $t("Utilisateurs") }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="/contacts-messages"
          class="nav-link"
          data-key="t-customers"
        >
          <i class="ri-user-voice-line"></i>
          <span data-key="t-dashboards">{{ $t("messages des contacts") }}</span>
        </router-link>
      </li>

      <!-- -------------------- -->
      <!--    Campagnes SMS     -->
      <!-- -------------------- -->
      <li class="menu-title">
        <span data-key="t-menu"> {{ $t("Campagnes SMS") }}</span>
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'campagnes-sms' }"
          class="nav-link"
          data-key="t-customers"
        >
          <i class="ri-user-heart-line"></i>
          <span data-key="t-dashboards"> {{ $t("Mes campagnes") }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'campagnes-sms-create' }"
          class="nav-link"
          data-key="t-customers"
        >
          <i class="ri-user-voice-line"></i>
          <span data-key="t-dashboards">{{ $t("Lancer une campagne") }}</span>
        </router-link>
      </li>

      <!-- ------------------------- -->
      <!-- gestions Generale du site -->
      <!-- ------------------------- -->
      <li class="menu-title">
        <span data-key="t-menu"> {{ $t("Gestion Generale du Site") }}</span>
      </li>
      <li class="nav-item">
        <router-link
          to="/general-setting"
          class="nav-link"
          data-key="t-customers"
        >
          <i class="ri-settings-line"></i>
          <span data-key="t-dashboards"> {{ $t("Gestion générale") }}</span>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link
          to="/faq-questions"
          class="nav-link"
          data-key="t-customers"
        >
          <i class="bx bx-store-alt"></i>
          <span data-key="t-dashboards"> {{ $t("FAQ Questions") }}</span>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link
          to="/profile-settings"
          class="nav-link"
          data-key="t-customers"
        >
          <i class="ri-settings-line"></i>
          <span data-key="t-dashboards"> {{ $t("Mon compte") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
  <!-- Sidebar -->
</template>
